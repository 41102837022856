import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import BackgroundImage from 'gatsby-background-image';
import Quote from '../quote/quote';

export default function Inspiration({ colorTheme, toggleQuote, image, text, quoter }) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const size = isMobile ? 'small' : 'large';

  return (
    <div>
      <BackgroundImage
        Tag="section"
        fluid={image.image.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        style={{
          height: '100vh',
          width: '100vw',
          backgroundPosition: 'center right',
          backgroundSize: 'cover'
        }}>
          <Quote toggleQuote={toggleQuote} size={size} colorTheme={colorTheme} quoter={quoter}>
            {text}
         </Quote>
      </BackgroundImage>
    </div>
  );
}
