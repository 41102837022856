import React from 'react';
import Inspiration from './inspiration';
import { useStaticQuery, graphql } from "gatsby"

export default function Ref3({toggleQuote}) {
  const image = useStaticQuery(graphql`
    query {
        image: file(relativePath: { eq: "ref3.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

  return (
    <Inspiration
      colorTheme="dark"
      toggleQuote={toggleQuote}
      image={image} text="Att våga måla färg inte bara på väggar, utan även på lister och tak, är något jag rekomenderar för ett personligt hem."
    />
    );
}