import React from 'react';
import Inspiration from './inspiration';
import { useStaticQuery, graphql } from "gatsby"

export default function Ref5({toggleQuote}) {
  const image = useStaticQuery(graphql`
    query {
        image: file(relativePath: { eq: "ref5.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

  return (
    <Inspiration
      colorTheme="light"
      toggleQuote={toggleQuote}
      image={image} text="Färg gör mig glad. Den glädjen vill jag förmedla vidare. "
    />
    );
}