import React, {useState} from "react"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar";
import Ref1 from "../components/references/ref1";
import Ref2 from "../components/references/ref2";
import Ref3 from "../components/references/ref3";
import Ref4 from "../components/references/ref4";
import Ref5 from "../components/references/ref5";

const ReferencePage = () => {

  const [page, setPage] = useState(1)

  const toggleQuote = () => {
    if( page >= 5 ) {
      setPage(1)
    } else {
      setPage(page + 1)
    }
  }

  let reference;
  switch (page) {
    case 1: reference = <Ref5 toggleQuote={toggleQuote} />
    break;
    case 2: reference = <Ref4 toggleQuote={toggleQuote} />
    break;
    case 3: reference = <Ref3 toggleQuote={toggleQuote} />
    break;
    case 4: reference = <Ref1 toggleQuote={toggleQuote} />
    break;
    case 5: reference = <Ref2 toggleQuote={toggleQuote} />
    break;
    default: reference = <Ref1 toggleQuote={toggleQuote} />
    break;
  }

 return (
  <>
     <SEO
       title="Målare i Åre, Järpen, Jämtland. Inspiration. Tips - The Måler"
       description="The Måler - måleriarbeten inom Åre-området med fokus hög kvalitet och yrkesstolthet. Färg livar upp på väggar lister och tak. Vitt eller kulör."
     />
     <Navbar page="INSPIRATION" />
     {reference}
  </>
)
}

export default ReferencePage
