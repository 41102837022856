import React from 'react';


export default function DivButton({ children, onClick }) {
  return (
    <div tabIndex={0} role="button" onMouseDown={() => onClick()} onClick={() => { }} onKeyPress={() =>onClick()}>
      {children}
    </div>
  );
}
