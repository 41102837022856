import React from 'react';

export default function StartQuoteDark({ size }) {
  
  const icon = size === 'large' ?
    <svg width="173" height="137" viewBox="0 0 173 137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.6 63.8C63.2667 66.7333 68.4667 71.1333 72.2 77C75.9333 82.8667 77.8 89.8 77.8 97.8C77.8 109.267 74.2 118.6 67 125.8C59.8 132.733 50.6 136.2 39.4 136.2C28.2 136.2 18.8667 132.6 11.4 125.4C4.19999 118.2 0.599991 109 0.599992 97.8C0.599993 92.4667 1.26666 87.1333 2.59999 81.8C3.93333 76.4667 6.86666 68.4667 11.4 57.8L34.6 0.199985L74.2 0.199988L56.6 63.8ZM151 63.8C157.667 66.7333 162.867 71.1333 166.6 77C170.333 82.8667 172.2 89.8 172.2 97.8C172.2 109.267 168.6 118.6 161.4 125.8C154.2 132.733 145 136.2 133.8 136.2C122.6 136.2 113.267 132.6 105.8 125.4C98.6 118.2 95 109 95 97.8C95 92.4667 95.6667 87.1333 97 81.8C98.3333 76.4667 101.267 68.4667 105.8 57.8L129 0.199993L168.6 0.199996L151 63.8Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="86.6094" y1="87" x2="86.6094" y2="221" gradientUnits="userSpaceOnUse">
          <stop stopColor="#192C4F" />
          <stop offset="0.432292" stopColor="#381619" />
        </linearGradient>
      </defs>
    </svg> :
    <svg width="65" height="52" viewBox="0 0 65 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.2465 24.55C23.7465 25.65 25.6965 27.3 27.0965 29.5C28.4965 31.7 29.1965 34.3 29.1965 37.3C29.1965 41.6 27.8465 45.1 25.1465 47.8C22.4465 50.4 18.9965 51.7 14.7965 51.7C10.5965 51.7 7.09647 50.35 4.29647 47.65C1.59647 44.95 0.246473 41.5 0.246473 37.3C0.246473 35.3 0.496473 33.3 0.996473 31.3C1.49647 29.3 2.59647 26.3 4.29647 22.3L12.9965 0.699998L27.8465 0.7L21.2465 24.55ZM56.6465 24.55C59.1465 25.65 61.0965 27.3 62.4965 29.5C63.8965 31.7 64.5965 34.3 64.5965 37.3C64.5965 41.6 63.2465 45.1 60.5465 47.8C57.8465 50.4 54.3965 51.7 50.1965 51.7C45.9965 51.7 42.4965 50.35 39.6965 47.65C36.9965 44.95 35.6465 41.5 35.6465 37.3C35.6465 35.3 35.8965 33.3 36.3965 31.3C36.8965 29.3 37.9965 26.3 39.6965 22.3L48.3965 0.700001L63.2465 0.700003L56.6465 24.55Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="32.5" y1="-8.99999" x2="32.5" y2="125" gradientUnits="userSpaceOnUse">
          <stop stopColor="#192C4F" />
          <stop offset="0.432292" stopColor="#381619" />
        </linearGradient>
      </defs>
    </svg>

  
  return icon
}
