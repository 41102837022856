import React from 'react';
import Inspiration from './inspiration';
import { useStaticQuery, graphql } from "gatsby"

export default function Ref4({toggleQuote}) {
  const image = useStaticQuery(graphql`
    query {
        image: file(relativePath: { eq: "ref4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

  return (
    <Inspiration
      colorTheme="light"
      toggleQuote={toggleQuote}
      image={image} text="Det ska vara lite kladdigt under processen. Låt mig ta hand om kaoset så blir du garanterad ett rent och snyggt resultat."
    />
    );
}