import React from 'react';
import Inspiration from './inspiration';
import { useStaticQuery, graphql } from "gatsby"

export default function Ref2({ toggleQuote }) {
  const image = useStaticQuery(graphql`
    query {
        image: file(relativePath: { eq: "ref2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `);

  return (
    <Inspiration
      colorTheme="dark"
      toggleQuote={toggleQuote}
      image={image} text="Teracotta är en härlig nyans som jag tror vi kommer se mer av på 2020-talet. "
    />
    );
}