import React from 'react';

export default function EndQuoteLight({ size }) {
  
  const icon = size === 'large' ?
    <svg width="87" height="69" viewBox="0 0 87 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.5047 36.6C55.1714 35.1333 52.5714 32.9333 50.7047 30C48.838 27.0667 47.9047 23.6 47.9047 19.6C47.9047 13.8667 49.7047 9.2 53.3047 5.6C56.9047 2.13333 61.5047 0.399998 67.1047 0.399999C72.7047 0.399999 77.3714 2.2 81.1047 5.8C84.7047 9.4 86.5047 14 86.5047 19.6C86.5047 22.2667 86.1714 24.9333 85.5047 27.6C84.838 30.2667 83.3714 34.2667 81.1047 39.6L69.5047 68.4L49.7047 68.4L58.5047 36.6ZM11.3047 36.6C7.97136 35.1333 5.37136 32.9333 3.50469 30C1.63803 27.0667 0.704692 23.6 0.704693 19.6C0.704693 13.8667 2.50469 9.19999 6.1047 5.6C9.7047 2.13333 14.3047 0.399994 19.9047 0.399994C25.5047 0.399995 30.1714 2.2 33.9047 5.8C37.5047 9.4 39.3047 14 39.3047 19.6C39.3047 22.2667 38.9714 24.9333 38.3047 27.6C37.638 30.2667 36.1714 34.2667 33.9047 39.6L22.3047 68.4L2.50469 68.4L11.3047 36.6Z" fill="#FCCFC9" />
    </svg>:
    <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1268 13.725C20.8768 13.175 19.9018 12.35 19.2018 11.25C18.5018 10.15 18.1518 8.85 18.1518 7.35C18.1518 5.2 18.8268 3.45 20.1768 2.1C21.5268 0.799996 23.2518 0.149996 25.3518 0.149996C27.4518 0.149996 29.2018 0.824997 30.6018 2.175C31.9518 3.525 32.6268 5.25 32.6268 7.35C32.6268 8.35 32.5018 9.35 32.2518 10.35C32.0018 11.35 31.4518 12.85 30.6018 14.85L26.2518 25.65L18.8268 25.65L22.1268 13.725ZM4.42677 13.725C3.17677 13.175 2.20177 12.35 1.50177 11.25C0.801768 10.15 0.451768 8.84999 0.451768 7.34999C0.451768 5.19999 1.12677 3.44999 2.47677 2.1C3.82677 0.799995 5.55177 0.149994 7.65177 0.149994C9.75177 0.149995 11.5018 0.824995 12.9018 2.175C14.2518 3.525 14.9268 5.25 14.9268 7.35C14.9268 8.35 14.8018 9.35 14.5518 10.35C14.3018 11.35 13.7518 12.85 12.9018 14.85L8.55177 25.65L1.12677 25.65L4.42677 13.725Z" fill="#FCCFC9" />
    </svg>  
  return icon
}
