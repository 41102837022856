import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import StartQuoteLight from '../icons/startQuoteLight';
import StartQuoteDark from '../icons/startQuoteDark';
import EndQuoteLight from '../icons/endQuoteLight';
import EndQuoteDark from '../icons/endQuoteDark';
import ArrowRightDark from '../icons/arrowRightDark';
import ArrowRightLight from '../icons/arrowRightLight';
import {motion} from 'framer-motion'
import DivButton from '../buttons/DivButton';

const useStyles = makeStyles(theme => ({
  backgroundContent: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  containter: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  innerContainer: { 
    maxWidth: 660,
    display: 'flex',
    flexDirection: 'column',
    color: settings => settings.fontColor,
    paddingBottom: 64,
  },
  topQuotationMark: {
    paddingLeft: 10,
  },
  bottomQuotationMark: {
    alignSelf: 'flex-end',
    paddingRight: 50,
  },
  quoter: {
    paddingTop: 10,
  },
  arrow: {
    '&:hover': {
      cursor: 'pointer',
    }, 
    paddingLeft: 32,
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  }
}));

export default function Quote({ toggleQuote, size, children, quoter, colorTheme }) {

  const startQuote = colorTheme === 'light'? 
    <StartQuoteLight size={size} /> :
    <StartQuoteDark size={size}/>;
  const endQuote = colorTheme === 'light'? 
    <EndQuoteLight size={size}/> :
    <EndQuoteDark size={size}/>;

  const arrow = colorTheme === 'light' ? 
    <ArrowRightLight size={size}/> : 
    <ArrowRightDark size={size}/>

  const settings = colorTheme === 'light'? 
  {
      fontColor: '#E9DED3'
  } : {
      fontColor: '#192C4F'
  };

  const classes = useStyles(settings);

  return (
    <div className={classes.backgroundContent}>
      <div className={classes.containter}>
        <div className={classes.innerContainer}>
          <div className={classes.topQuotationMark}>
            {startQuote}
          </div>
          <div>
            <Typography variant="h4">{children}</Typography>
          </div>
          {quoter && 
            <div className={classes.quoter}>
              <Typography variant="h6">{quoter}</Typography>
            </div>
          }
          <div className={classes.bottomQuotationMark}>
            {endQuote}
          </div>
        </div>
        <motion.div className={classes.arrow}
          whileHover={{
            x: 30
          }}
          whileTap={{ scale: 0.9 }}
        >
          <DivButton onClick={toggleQuote}>
            {arrow}
          </DivButton>
        </motion.div>
      </div>
    </div>
  );
}

/**
 * Type definitions
 */
Quote.propTypes = {
  colorTheme: PropTypes.string.isRequired,
  quoter: PropTypes.string,
};

Quote.defaultProps = {
  quoter: null,
}