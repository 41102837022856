import React from 'react';

export default function StartQuoteLight({ size }) {
  const icon = size === 'large' ?
    <svg width="173" height="137" viewBox="0 0 173 137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.9906 63.8C63.6573 66.7333 68.8573 71.1333 72.5906 77C76.324 82.8667 78.1906 89.8 78.1906 97.8C78.1906 109.267 74.5906 118.6 67.3906 125.8C60.1906 132.733 50.9906 136.2 39.7906 136.2C28.5906 136.2 19.2573 132.6 11.7906 125.4C4.59063 118.2 0.990629 109 0.99063 97.8C0.990631 92.4667 1.6573 87.1333 2.99063 81.8C4.32397 76.4667 7.2573 68.4667 11.7906 57.8L34.9906 0.199991L74.5906 0.199995L56.9906 63.8ZM151.391 63.8C158.057 66.7333 163.257 71.1333 166.991 77C170.724 82.8667 172.591 89.8 172.591 97.8C172.591 109.267 168.991 118.6 161.791 125.8C154.591 132.733 145.391 136.2 134.191 136.2C122.991 136.2 113.657 132.6 106.191 125.4C98.9906 118.2 95.3906 109 95.3906 97.8C95.3906 92.4667 96.0573 87.1333 97.3906 81.8C98.724 76.4667 101.657 68.4667 106.191 57.8L129.391 0.199999L168.991 0.200003L151.391 63.8Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="87" y1="-23" x2="87" y2="331" gradientUnits="userSpaceOnUse">
          <stop offset="0.234375" stopColor="#F3DCC6" />
          <stop offset="0.447917" stopColor="#FCCFC9" />
        </linearGradient>
      </defs>
    </svg>:
    <svg width="66" height="52" viewBox="0 0 66 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.7465 24.55C24.2466 25.65 26.1966 27.3 27.5965 29.5C28.9965 31.7 29.6965 34.3 29.6965 37.3C29.6965 41.6 28.3465 45.1 25.6465 47.8C22.9465 50.4 19.4965 51.7 15.2965 51.7C11.0965 51.7 7.59655 50.35 4.79655 47.65C2.09655 44.95 0.746547 41.5 0.746547 37.3C0.746547 35.3 0.996548 33.3 1.49655 31.3C1.99655 29.3 3.09655 26.3 4.79655 22.3L13.4966 0.700005L28.3466 0.700006L21.7465 24.55ZM57.1466 24.55C59.6466 25.65 61.5966 27.3 62.9966 29.5C64.3966 31.7 65.0966 34.3 65.0966 37.3C65.0966 41.6 63.7466 45.1 61.0465 47.8C58.3465 50.4 54.8965 51.7 50.6965 51.7C46.4965 51.7 42.9965 50.35 40.1965 47.65C37.4965 44.95 36.1465 41.5 36.1465 37.3C36.1465 35.3 36.3965 33.3 36.8965 31.3C37.3965 29.3 38.4965 26.3 40.1966 22.3L48.8966 0.700008L63.7466 0.700009L57.1466 24.55Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="33.0001" y1="-119" x2="33" y2="235" gradientUnits="userSpaceOnUse">
          <stop offset="0.234375" stopColor="#F3DCC6" />
          <stop offset="0.447917" stopColor="#FCCFC9" />
        </linearGradient>
      </defs>
    </svg>

  return icon

}
